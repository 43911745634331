// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 100%;
		clear:left;
		font-weight:bold;

		@include breakpoint(small) {
			width: 40%;
			font-weight:normal;
		}
	}

	dd {
		width: 100%;

		@include breakpoint(small) {
			width: 60%;
		}
	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.hide-on-mobile {
	display:none;

	body:not(.index) & {
		display:block;
	}

	@include breakpoint(medium) {
		display:block;
	}
}

.hide-on-desktop {
	@include breakpoint(medium) {
		display:none;
	}
}

.highlight {
	color: $medium;
}

.btn {
	background: $medium;
	border: none;
	color: $light;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms background ease-in-out, 300ms color ease-in-out;

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// Overlay
///////////////////////////////
.overlay{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index: 100;
	background: $light;

	.nojs & {
		display:none;
	}
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top:rem($menu-bar);
	overflow:hidden;

	
	@include breakpoint(medium) {
		padding-top:0;
		transition:300ms all ease-in-out;
	}
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	// padding: rem(10px) rem($base-gap);
	width: 100%;
	height:auto;
	min-height:rem(550px);
	overflow:hidden;

	@media screen and (min-width:400px) {
		min-height:rem(600px);
	}

	@media screen and (min-width:550px) {
		min-height:rem(750px);
	}

	@include breakpoint(medium) {
		height:100vh;
		min-height:rem(908px);
	}

	.left-bg{
		position:absolute;
		bottom:0;
		left:0;
		width:50%;
		background:$secondary;
		z-index:0;

		.nojs & {
			height:100%;
		}
	}
	.right-bg{
		position:absolute;
		right:0;
		width:50%;
		top:0;
		background:$primary;
		z-index:0;

		body:not(.index) & {
			background:$light;
		}

		.nojs & {
			height:100%;
		}
	}
	
	// top-line
	.top-line{
		position:fixed;
		top:0;
		width:100%;
		z-index:10;
		// padding:rem(34px) 0;
		transition:300ms background ease-in-out, 300ms height ease-in-out;

		.nojs & {
			background:$secondary;
		}

		// Sticky header
		
		&.sticky {
			background:#f7f2ed;
			height:rem(88px);

			ul {
				@include breakpoint(medium) {
					padding: {
						top:rem(22px);
					}
				}
			}

			label[for="navi-toggled"] {
				span {
					display:none;
				}
			}

			.small-branding {
				body:not(.index) & {
					opacity:1;
					visibility:visible;
					padding: {
						top:rem(23px);
					}
				}
				@include breakpoint(medium) {
					opacity:1;
					visibility:visible;
				}
			}
		} // end of sticky header

		// Top line content
		.top-line-content{
			display:none;

			@include breakpoint(medium) {
				display:block;
			}

			@include breakpoint(giant) {
				display:flex;
				justify-content:space-between;
			}

			label[for="navi-toggled"] {
				display:none;

				@include breakpoint(medium) {
					display:inline-block;
				}

				@include breakpoint(giant) {
					display:block;
				}
			}
		} // end of top-line-content

		// header contacts ul
		ul{
			display:block;
			
			text-align:center;
			top:rem(100px);
			position: absolute;

			@include breakpoint(medium) {
				width:rem(600px);
				position: relative;
				padding: {
					top:rem(32px);
				}
				top:auto;
				float:right;
				transition:300ms padding ease-in-out;
			}

			@include breakpoint(large) {
				width:rem(800px);
			}

			@include breakpoint(giant) {
				width:rem(725px);
				margin-right:-13%;
				margin-left:rem(20px);
				float:none;
			}

			li{
				white-space:nowrap;
				color:$dark;
				display:inline-block;
				font-size:rem(16px);

				&.divider{
					// margin: 0 rem(16px);

					@include breakpoint(large) {
						margin: 0 rem(16px);
					}
				}
				&.last{
					margin-right: 0;
				}

				a{
					color:$dark;
					text-decoration:none;

					&:hover, &:focus{
						color:$tertiary;
					}
				}
			}
		} // end ul

		// header small branding

		.small-branding{
			width:rem(255px);
			opacity:0;
			visibility:hidden;
			background:rgba($primary, 0);
			display:none;
			transition:300ms opacity ease-in-out, 300ms visibility ease-in-out, 300ms padding ease-in-out;
			padding: {
				top:rem(23px);
			}

			body:not(.index) & {
				opacity:1;
				visibility:visible;
				padding: {
					top:rem(33px);
				}
			}

			@include breakpoint(giant) {
				display:block;
			}
		} // end small- branding
	} // end .top-line

	// header content
	.header-content{
		position:absolute;
		left:0;
		top:0;
		bottom:0;
		right:0;

		body:not(.index) & {
			position: relative;

			@include breakpoint(medium) {
				position: absolute;
			}
		}

		.row, .col{
			height:100%;
			position:relative;
		}

		.left-col{
			display:none;

			body:not(.index) & {
				
				width:calc(100% - 18px);
				padding: {
					top:rem(30px);
				}
				max-height: 80%;
				display:block;

				@include breakpoint(medium) {
					position:absolute;
					top:calc(50% - #{rem(50px)});
					transform:translateY(calc(-50% - 0.5px));
					padding-top:0;
				}

				@include breakpoint(large) {
					padding-left:rem($base-gap);
				}

				figure{
					width:56.7%;
					overflow:hidden;

					&.image-2 {
						position: absolute;
						bottom:rem(-113px);
						right:rem($base-gap);

						@include breakpoint(medium) {
							right:0;
						}

						img {
							width:auto;
						}
					}

					img{
						position:relative;
					}
				}

				.link{
					font-size:rem(16px);
					line-height:rem(24px);
					color:$dark;
					width:100%;
					display:block;
					text-decoration:none;

					&:hover {
						color:$medium;
					}

					@include breakpoint(medium) {
						padding: {
							right:rem($base-gap);
						}
					}

					@include breakpoint(large) {
						padding: {
							right:rem(30px);
						}
					}

					@include breakpoint(giant) {
						padding: {
							right:0;
						}
					}

					strong{
						display:block;
						text-align:center;

						@include breakpoint(medium) {
							text-align:left;
							line-height:1.5rem;
						}

						@include breakpoint(giant) {
							text-align:center;
							line-height:rem(24px);
						}
					}

					span{
						position:relative;
						padding-left: rem(30px);
						display:inline-block;
						margin-left:rem(10px);
						
						i{
							font-size:rem(18px);
						}

						&:before{
							content:'';
							display:block;
							background:image-url('layout/icon.png') center no-repeat;
							background-size: contain;
							width:rem(25px);
							height:rem(44px);
							position:absolute;
							left:0;
							top:rem(-8px);

							@include breakpoint(medium) {
								top:0;
							}

							@include breakpoint(giant) {
								top:rem(-8px);
							}
						}
					}
				}
			}

			body.cmsbackend & {
				top:rem(100px);
				transform:none;
				figure.image-2 {
					position: relative;
					bottom:auto;
					right:auto;
				}
				
			}

			@include breakpoint(medium) {
				position:absolute;
				width:calc(100% - 18px);
				padding-left:rem($base-gap);
				max-height: 80%;
				top:calc(50% - #{rem(50px)});
				transform:translateY(calc(-50% - 0.5px));
				display:block;

				figure{
					width:56.7%;
					overflow:hidden;

					&.image-2 {
						position: absolute;
						bottom:rem(-113px);
						right:0;

						img {
							width:auto;
						}
					}

					img{
						position:relative;
					}
				}

				.link{
					font-size:rem(16px);
					line-height:rem(24px);
					color:$dark;
					width:100%;
					display:block;
					text-decoration:none;
					transition:300ms color ease-in-out;

					&:hover {
						color:$tertiary;
					}

					@include breakpoint(medium) {
						padding: {
							right:rem($base-gap);
						}
					}

					@include breakpoint(large) {
						padding: {
							right:rem(30px);
						}
					}

					@include breakpoint(giant) {
						padding: {
							right:0;
						}
					}

					strong{
						display:block;
						text-align:center;

						@include breakpoint(medium) {
							text-align:left;
							line-height:1.5rem;
						}

						@include breakpoint(giant) {
							text-align:center;
							line-height:rem(24px);
						}
					}

					span{
						position:relative;
						padding-left: rem(30px);
						display:inline-block;
						margin-left:rem(10px);
						
						i{
							font-size:rem(18px);
						}

						&:before{
							content:'';
							display:block;
							background:image-url('layout/icon.png') center no-repeat;
							background-size: contain;
							width:rem(25px);
							height:rem(44px);
							position:absolute;
							left:0;
							top:rem(-8px);

							@include breakpoint(medium) {
								top:0;
							}

							@include breakpoint(giant) {
								top:rem(-8px);
							}
						}
					}
				}
			}

			@include breakpoint(large) {
				top:50%;
				transform:translateY(-50%);
			}
		}
		.right-col{
			padding: {
				top:rem(130px);
			}
			position: relative;

			.text-container {

				@include breakpoint(medium) {
					margin: {
						left:rem(2 * $base-gap);
						bottom:rem(50px);
					}
				}

				@include breakpoint(large) {
					margin: {
						bottom:rem(119px);
						left:rem(146px);
					}
				}
				
			}

			img.right-img {
				width: rem(220px);

				@media screen and (min-width:400px) {
					width:rem(300px);
				}

				@media screen and (min-width: 550px) {
					width: rem(450px);
				}

				@include breakpoint(tiny) {
					// width:rem(320px);
				}

				@include breakpoint(medium) {
					width:100%;
				}
			}

			@include breakpoint(medium) {
				position:absolute;
				width:100%;
				bottom:0;
				left:0;
				max-height: 80%;
				overflow: hidden;
				padding-right:15%;
				padding-top:rem(30px);

				figure{
					margin-bottom:0;
					padding-right:rem(30px);

					img{
						position:relative;
					}

					figcaption{
						left:rem(3px);
					}
				}
			}

			@include breakpoint(large) {
				padding-top:0;

				figure {
					figcaption {
						right:0;
						left:auto;
					}
				}
			}
		}


		.siel{
			position:absolute;
			top:rem(76px);
			right:11%;
			margin-right:rem(4px);
			display:none;

			@include breakpoint(large) {
				display:inline-block;
				right:rem($base-gap);
			}

			@include breakpoint(large) {
				right:11%;
			}
		}
	} // end .header-content

	.scroll-btn-line{
		position:absolute;
		bottom:0;
		width:100%;
		left:0;
		line-height:0;
	}
	.weiter{
		font-size:rem(14px);
		background:$medium;
		color:$light;
		display:inline-block;
		line-height:1;
		padding:rem(6px) rem(19px) rem(4px);
		cursor:pointer;

		.nojs & {
			display:none;
		}

		i{
			font-size:rem(10px);
			margin-left:rem(3px);
			position:relative;
			top:rem(-2px);
		}
	}

	.weiter-nojs {
		display:none;
		font-size:rem(14px);
		background:$medium;
		color:$light;
		line-height:1;
		padding:rem(6px) rem(19px) rem(4px);
		cursor:pointer;

		i{
			font-size:rem(10px);
			margin-left:rem(3px);
			position:relative;
			top:rem(-2px);
		}

		.nojs & {
			display:inline-block;
		}
	}

	.branding {
		display: block;
		// float: right;
		padding-top:rem(20px);

		@include breakpoint(small) {
			padding-top:0;
		}

		@include breakpoint(medium) {
			padding: {
				top:rem(110px);
			}
		}

		@include breakpoint(large) {
			position:absolute;
			left:50%;
			top:50%;
			transform:translate(calc(-50% - 0.5px),calc(-50% - 0.5px));
			padding-top:0;
			float:none;
		}
	}
}
header .header-content .right-col {
	height:100%;

	@include breakpoint(medium) {
		height:auto;
	}
}
header .header-content .right-col .link {
	position: absolute;
	bottom:rem(30px);
	font-size:rem(16px);
	line-height:rem(24px);
	color:$dark;

	&:hover {
		color:$tertiary;
	}

	strong {
		display:block;
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	padding:rem(105px) 0 5rem;

	.image-item,
	.cms-image-item {
		margin: {
			bottom:rem(50px);
		}
		overflow:hidden;
		@include breakpoint(medium) {
			margin: {
				bottom:rem(148px);
			}
		}

		a {
			margin: {
				bottom:rem(23px);
			}
		}

		span {
			display:block;
			font-size:rem(16px);
			line-height:rem(24px);

			&.image-description {
				font-weight:bold;
			}
		}
	}

	@include breakpoint(medium) {
		padding:rem(105px) 0 rem(140px);

		body:not(.index) & {
			padding:rem(142px) 0 rem(140px);
		}
	}

	.content-1{

		@include breakpoint(medium) {

			figcaption {
				left: rem(50px);
			}
		}

		@include breakpoint(large) {
			figcaption {
				left: auto;
			}
		}

		@include breakpoint(giant) {
			margin-bottom:rem(90px);
		}

	}

	.content-3 {
		figure {
			// overflow:hidden;
		}
	}

	.row.middle {

		.hide {
			display:none;

			@include breakpoint(large) {
				display:block;
			}
		}
	}

	.slideshows-row {
		display:none;

		@include breakpoint(medium) {
			display:block;
			margin-top:rem(90px);

			.nojs & {
				display:none;
			}

			.col.first-slideshow {
				padding:0;
			}
			.col.second-slideshow {
				padding-left:0;

				li {
					padding:0;
					max-width:rem(217px);
				}
			}
		}

		@include breakpoint(large) {
			.col.first-slideshow,
			.col.second-slideshow {
				padding: {
					left:rem($base-gap);
					right:rem($base-gap);
				}
			}
		}

		@include breakpoint(giant) {
			margin-top:0;
		}
	}

	.headline{
		position:relative;

		&:before{
			content:'';
			display:none;
			position:absolute;
			right:0;
			width:88.5%;
			background:$secondary;
			top:0;
			height:rem(208px);
			z-index: -1;

			@include breakpoint(medium) {
				display:block;
			}
		}
	}

	p{
		position:relative;
		z-index:1;
	}

	.negative-margin-top{

		@include breakpoint(large) {
			margin-top:rem(-78px);
		}
	}

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		.nojs & {
			display:none;
		}

		@include breakpoint(medium) {
			height: rem(521px);
			margin: {
				top:rem(9px);
			}
		}
	}

	.signature{
		text-align:right;
		color:$dark;
		font-family:$secondary-font;
		font-size:rem(28px);
		line-height:rem(50px);
		padding-top:rem(18px);

		span{
			display:block;
			padding-right: 23%;
			font-family:$main-font;
			color:$medium;
			font-size:rem(30px);
		}
	}

	

	.teaser-area{
		padding-bottom:rem(91px);
		display:none;

		@include breakpoint(medium) {
			display:block;
			padding-bottom:0;
		}

		@include breakpoint(large) {
			padding-bottom:rem(91px);
		}

		.teaser{
			padding:rem(145px) rem($base-gap) rem(27px);
			margin-bottom:rem($base-gap);
			position:relative;
			overflow:hidden;

			a{
				color:$dark;
				text-decoration:none;
				font-size:rem(16px);
				line-height:1.500em;
				position:static;

				&:before{
					content:'';
					display:block;
					position:absolute;
					width:100%;
					height:100%;
					top:0;
					left:0;
					z-index:-1;
					transition:background 0.3s ease-in-out;
				}
				&:hover{
					&:before{
						background:$secondary;
					}
				}
			}

			strong{
				text-transform:uppercase;
			}

			.title{
				padding-top:rem(16px);
			}
		}
	} // end .teaser-area

	.negative-prefix-1{
		@include breakpoint(medium) {
			margin-left:-8.33333%;
		}
	}

	.margin-top{
		position:relative;
		margin-top:10.33333%;

		.firefox & {
			margin-top:rem(157px);
		}
	}
} // end main
figure{
	position:relative;
	margin-bottom: rem(34px);

	a{
		margin-bottom:0;
	}

	i{
		position:absolute;
		bottom:rem(14px);
		right:rem(16px);
	}

	// &:first-child{
	// 	margin-top: rem(-39px);
	// }

	figcaption{
		body:not(.cmsbackend) &.vertical{
			position:absolute;
			text-transform:uppercase;
			font-size:rem(16px);
			line-height:1.2em;	
			top:-1.2rem;

			@include breakpoint(large) {
				word-wrap: break-word;
				white-space:pre-wrap;
				right:rem(-20px);
				top:0;
				width:rem(5px);

				body.cmsbackend & {
					position: relative;
				}

				&.left {
					right:auto;
					left:rem(-20px);
				}
			}

			@include breakpoint(huge) {
				top:rem(26px);
			}

			i{
				display:inline-block;
				right:rem(-20px);
				top:0;

				@include breakpoint(large) {
					left:rem(-2px);
					top:100%;
					right:auto;
				}
			}
		}

		&.padding-right{
			display:block;
			padding-right:0;
			font-size:rem(16px);
			line-height:1.500em;
			text-align:right;

			@include breakpoint(medium) {
				padding-right:20%;
			}

			@include breakpoint(large) {
				padding-right:36%;
			}
		}
	}
} // end figure

// Slider
.slideshow{
	position:relative;
	margin-bottom:rem(110px);

	.h2{
		display:inline-block;
		position:absolute;
		left:0;
		top:rem(-90px);
		background:$medium;
		color:$light;
		padding:rem($base-gap);
		z-index:1;

		@include breakpoint(giant) {
			padding:rem(65px) rem(25px) rem(15px) rem(80px);
			left:rem($base-gap);
			top:rem(-40px);
		}

		@include breakpoint(huge) {
			left:rem(126px);
			padding:rem(65px) rem(25px) rem(15px) rem(147px);
		}
	}
	.vertical-text{
		position:absolute;
		width:rem(5px);
		word-wrap: break-word;
		text-transform:uppercase;
		font-size:rem(16px);
		line-height:1.2em;
		white-space:pre-wrap;
		left:rem(-30px);
		top:rem(26px);

	}
} // end .slideshow

#main-slider{
	z-index:0;

	.slide{
		padding:0;

		.image{
			width:100%;
			height:rem(655px);
			background-size:cover !important;
		}
	}
} // end #main-slider

#slider-nav{
	height:rem(655px);

	.slick-list{
		display:block;
		height:100% !important;
		overflow:visible !important;
	}
	.slide{
		border:0;
		cursor:pointer;
		overflow:hidden;

		div{
			background:$primary;
			display:block;
			font-size:rem(18px);
			white-space:nowrap;
			padding:rem(19.4px) rem(23px);
			transition:300ms width ease-in-out, 300ms margin ease-in-out;
			position: relative;

			span:not(.show-on-hover) {
				font-weight:bold;
				text-transform:uppercase;
				display:inline-block;
				min-width: rem(275px);
			}

			span.show-on-hover {
				opacity:0;
				visibility:hidden;
				transition:300ms opacity ease-in-out, 300ms visibility ease-in-out;
				position: absolute;
				right:rem(30px);
				top:auto;
				font-size:rem(16px);
				// line-height:rem(24px);
			}

			i {
				opacity:0;
				transition:300ms opacity ease-in-out;
			}
		}

		&:nth-child(odd){
			div{
				background:$secondary;
			}
		}

		&:hover, &.slick-current{
			overflow:visible;
			div{
				background:$medium;
				color:$light;
				// width:rem(470px) !important;
				margin: {
					left:rem(-253px);
				}

				span:not(.show-on-hover) {
					padding: {
						left:rem(23px)
					}
				}

				span.show-on-hover {
					opacity:1;
					visibility:visible;
				}

				i {
					opacity:1;
				}
			}
		}
	}
} // end #slider-nav


// FOOTER
//////////////////////////////

.footer {
	display: block;
	background:$primary;
	position:relative;
	border-bottom:rem(12px) solid $medium;
	padding:rem(69px) 0;
	text-align:center;

	@include breakpoint(medium) {
		text-align:left;
	}

	&:before{
		content:'';
		display:block;
		width:50%;
		position:absolute;
		background:$secondary;
		right:0;
		top:0;
		bottom:0;
		z-index:0;
	}

	.siel {
		text-align:center;
		margin-bottom:rem($base-gap);

		img {
			width:auto;
		}
	}

	ul{
		font-size:rem(16px);
		line-height:1.500em;

		li{
			span{
				display:inline-block;
				width:rem(67px);
			}
			a{
				color:$dark;
				text-decoration:none;
				transition:300ms color ease-in-out;

				&:hover {
					color:$tertiary;
				}
			}
		}
	}

	h5{
		margin-bottom: 0;
	}
}

// Loading animation
.sk-cube-grid {
  width: 40px;
  height: 40px;
  position:fixed;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%) trnaslateZ(0);
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $secondary;
  float: left;
  position:relative;
  z-index:10;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

// Email fix

.escape.email {
	span {
		display:inline;

		span {
			display:inline;
		}
	}
}