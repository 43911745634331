// Main Navigation
//////////////////////////////

.navi-main {

	margin: {
		top:rem(35px);
	}

	li {
		a,
		span {
			transition:300ms color ease-in-out;
			font-size:rem(30px);
			line-height:rem(50px);
			color:$primary;
			text-decoration:none;
			font-weight:normal;

			@include breakpoint(medium) {
				font-size:rem(40px);
				line-height:rem(70px);
			}

			@include breakpoint(large) {
				font-size:rem(50px);
				line-height:rem(80px);
			}
		}

		&.hasSub {
			position: relative;

			& > span {
				label {
					cursor:pointer;
				}
			}
		}

		&.anfahrt,
		&.newsletter,
		&.datenschutzerklaerung,
		&.impressum {
			@include breakpoint(medium) {
				display:none;
			}
		}
	}

	& > li {
		&:hover {
			& > a,
			& > span {
				color:$medium;
			}
		}

		&.active,
		&:active,
		&:focus {
			& > span,
			& > a {
				color:$medium;
			}
		}
	}

	.sub {
		visibility:hidden;
		opacity:0;
		transition:300ms height ease-in-out, 300ms opacity ease-in-out, 300ms visibility ease-in-out, 300ms margin ease-in-out, 300ms padding ease-in-out;
		padding: {
			left:rem(37px);
			right:rem(35px);
		}
		border-bottom:1px solid $primary;
		height:0;

		@include breakpoint(tiny) {
			padding: {
				left:rem(20px);
				right:rem(20px);
			}
			display:flex;
			justify-content:space-between;
		}

		@include breakpoint(large) {
			padding: {
				left:rem(37px);
				right:rem(35px);
			}
		}

		li {
			display:block;
			margin: {
				right: rem(30px);
			}

			@include breakpoint(tiny) {
				display:inline-block;
				margin: {
					right:0;
				}
			}

			// @include breakpoint(giant) {
			// 	margin: {
			// 		right:rem(30px);
			// 	}
			// }

			&.kuechen {
				margin: {
					right:0;
				}
			}
		}

		a {
			font-size:rem(24px);
			line-height:rem(50px);
			text-transform:uppercase;
			font-weight:bold;
			transition:300ms color ease-in-out;

			@include breakpoint(tiny) {
				font-size:rem($base-font-size);
			}

			@include breakpoint(small) {
				font-size:rem(28px);
			}

			@include breakpoint(large) {
				font-size:rem(34px);
				line-height:rem(80px);
			}

			&.active,
			&:hover,
			&:active,
			&:focus {
				color:$medium;
			}
		}
	}
}

#open-submenu {
	display:none;
	&:checked {
		~ * label[for="open-submenu"] {
			color:$medium;
		}
		~ .navi-main {
			.sub {
				visibility:visible;
				opacity:1;
				height:rem(252px);
				padding: {
					top:rem(26px);
					bottom:rem(26px);
				}
				margin: {
					bottom:rem(35px);
				}

				@include breakpoint(tiny) {
					height:rem(102px);
				}

				@include breakpoint(medium) {
					height:rem(106px);
					padding: {
						bottom:0;
					}
					margin: {
						bottom:rem(55px);
					}
				}
			}
		}
	}
}

// Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

	background:$dark;
	right:-100%;
	overflow-y: auto;
	position: fixed;
	top:rem($menu-bar);
	transition:.6s;
	// width:map-get($breakpoints, tiny) * .8;
	width:100%;
	z-index: 895;
    bottom: 0;
    max-width:100%;

    .top-line-open-navi {
		label[for="navi-toggled"] {
			display:none;
		}
	}

	// Desktop Navigation
	//////////////////////////////

	@include breakpoint(medium) {
		bottom:auto;
		left:auto;
		position: relative;
		top:auto;
		width: auto;
		height:0;
		transition:600ms all ease-in-out;
		right:auto;

		.top-line-open-navi {
			label[for="navi-toggled"] {
				display:block;
				width:rem(86px);

				&:before {
					background-image:image-url("menu-close.png");
					width:rem(65px);
					height:rem(66px);
				}
			}
		}
	}
}

.mobile-bar {
	align-items:center;
	background:$dark;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 894;

	li {
		display:flex;
		flex-grow:1;
		width:50%;

		&.first-li {
			background:$tertiary;
		}

		&:last-child {
            justify-content:flex-end;
        }

        label {
        	color:$light;
        	cursor:pointer;
        	padding: {
        		top:rem(8px);
        		bottom:rem(8px);
        		left:rem($base-gap);
        		right:rem($base-gap);
        	}
        	width:100%;
        	text-align:center;

        	&::before {
        		color:$light;
        	}
        }

        a {
        	font-size:rem(16px);
        	text-decoration:none;
        	color:$light;

        	i {
        		color:$light;
        	}

        	&:hover {
        		text-decoration:underline;
        	}
        }
	}

	@include breakpoint(medium) {
		display: none;
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);
	line-height:rem(21px);
	color:$tertiary;
	position: relative;
	padding: {
		left:rem(22px);
	}
	transition:800ms all ease-in-out;
	cursor:pointer;

	@include breakpoint(medium) {
		padding: {
			top:rem(28px);
		}
		font-weight:bold;
		text-transform:uppercase;
		transition:300ms padding ease-in-out;

		.nojs & {
			padding: {
				top:rem(14px);
				bottom:rem(10px);
			}
		}

		.top-line.sticky & {
			padding: {
				top:rem($base-gap);
			}
		}
	}

	span {

		@include breakpoint(large) {
			position:absolute;
			width:rem(5px);
			word-wrap: break-word;
			text-transform:uppercase;
			white-space:pre-wrap;
			left:rem(48px);
			top:rem(95px);
		}
	}

	&:before {
		@extend .fa-default;
		margin-right: rem(10px);
		display:none;

		@include breakpoint(medium) {
			background-image:image-url("menu-icon.png");
			background-size:cover;
			width:rem(64px);
			height:rem(50px);
			content:"";
			display:block;
		}
	}

	.burger {
        width:18px;
        height:14px;
        position: relative;
        z-index: 20;         
        display: inline-block; 
        left:auto;
        top:auto;      

        span {
            transition:.5s;
            top:1px;
            left:0;
            height:1px;
            width: 100%;
            background:$light;
            position: absolute;
            transform-origin: top left;
        }

        &:before, &:after {
            transition:0.3s;
            position: absolute;
            left:0;
            height:1px;
            width: 100%;
            background: $light;
            display: block;
            content: '';
        }

        &:before {
            top:50%;
            transform-origin: top left;
        }

        &:after {
            bottom:0;
            transform-origin: bottom left;
        }
    }

	// &:after {
	// 	background:rgba($dark,0.8);
	// 	bottom:0;
	// 	content:'';
	// 	left:0;
	// 	opacity: 0;
	// 	pointer-events:none;
	// 	position: fixed;
	// 	top:rem($menu-bar);
	// 	transition:opacity .6s;
	// 	width: 100%;
	// 	z-index:-1;
	// }
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			.burger {

		        &:before {
		            opacity: 0;
		            visibility: hidden;
		        }

		        &:after {
		            transform:rotate(-45deg);
		            margin-left: rem(7px);
		        }

		        span {
		            transform:rotate(45deg);
		            margin-left: rem(7px);
		        }
		    }

			@include breakpoint(medium) {
				content:"";

				&::before {
					content:"";
				}
			}

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		.navi-main {
			opacity:1;
			visibility:visible;
		}

		~ * .page-navi,
		~ .page-navi {
			right:0;

			@include breakpoint(medium) {
				width:100%;
				height:100vh;
				position: fixed;
				top:0;
				right:auto;
				left:0;
			}
		}
	}
}

.contact-box {
	background:$primary;
	left:-100%;
	overflow-y: auto;
	position: fixed;
	top:rem($menu-bar);
	transition:.6s;
	width:100%;
	z-index: 895;
    bottom: 0;
    max-width:100%;

    @include breakpoint(medium) {
    	display:none;
    }

    a.small-branding {
    	display:block;
    	margin: {
    		top:rem(20px);
    	}
    }

    span {
    	display:block;
    	text-align:center;

    	&.phone,
    	&.fax,
    	&.email {
    		font-size:rem(30px);
    		color:$tertiary;
    		margin: {
    			top:rem(10px);
    			bottom:rem(10px);
    		}
    	}

    	&.email {
    		font-size:rem(24px);
    		margin: {
    			top:rem(30px);
    			bottom:rem(50px);
    		}
    	}

    	&.address {
    		font-size:rem(40px);
    		line-height:rem(50px);
    	}

    	i {
    		color:$tertiary;
    	}

    	a {
    		color:$tertiary;
    		transition:300ms color ease-in-out;
    		text-decoration:none;

    		&:hover {
    			color:$dark;
    		}
    	}

    	&.heading {
			font-size:rem(28px);
			font-weight:bold;
			text-align:center;
			margin:rem(20px) auto rem(50px);
			max-width:rem(274px);
    	}
    }

	@include breakpoint(medium) {
		bottom:auto;
		left:auto;
		position: relative;
		top:auto;
		width: auto;
		height:0;
		transition:600ms all ease-in-out;
	}
}

#contact-box {
	display:none;

	&:checked {
		~ * label[for="contact-box"] {

		}

		~ * .contact-box,
		~ .contact-box {
			left:0;
			height:100vh;

			@include breakpoint(medium) {
				widows: 100%;
				height:100vh;
				position: fixed;
				top:0;
				right:auto;
				left:0;
			}
		}
	}
}

// Additional navi

.add-navi {
	display:flex;
	justify-content:flex-start;
	margin: {
		top:rem(86px);
	}
	display:none;

	@include breakpoint(medium) {
		display:block;
	}
	li {
		display:inline-block;
		margin-right:rem(28px);

		&:active,
		&:hover,
		&.active,
		&:focus {
			a {
				color:$medium;
			}
		}

		&:last-of-type {
			margin-right:0;
		}
		a {
			color:$border;
			font-size:rem(20px);
			line-height:rem(49px);
			text-decoration:none;
			transition:300ms color ease-in-out;
		}
	}
}