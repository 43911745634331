// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);
// Rajdhani
@include font-face('Rajdhani',
    'rajdhani-regular-webfont.woff',
    'rajdhani-regular-webfont.ttf',
    'rajdhani-regular-webfont.svg',
    'rajdhani-regular-webfont.eot',
    400
);
@include font-face('Rajdhani',
    'rajdhani-bold-webfont.woff',
    'rajdhani-bold-webfont.ttf',
    'rajdhani-bold-webfont.svg',
    'rajdhani-bold-webfont.eot',
    700
);
// Vibur
@include font-face('Vibur',
    'vibur-regular-webfont.woff',
    'vibur-regular-webfont.ttf',
    'vibur-regular-webfont.svg',
    'vibur-regular-webfont.eot'
);